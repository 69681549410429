import React from "react";
import CardDec1 from "../Images/CardDec1.svg";
import CardDec2 from "../Images/CardDec2.svg";
// import SmartAdvantage from "../Images/SmartAdvantage.svg";
// import JSIVelocity from "../Images/JSI-Velocity.svg";
import JSIVelocity from "../Images/JSI_NewGradient_Official_JSIVelocityWhite_v20230309.png";

import { Fragment } from "react";

const Card = ({
  cardDetails,
  user,
  appLink,
  appUrl,
  extAppLink,
  extAppUrl,
}) => {
  // console.log("USER CARD", user);

  // const displayDashboard = (url) => {
  //   if (url)
  //     return (
  //       <a href={url} target="_blank">
  //         Go to Dashboard {">"}{" "}
  //       </a>
  //     );
  //   else
  //     return <a className="card__footer__disabled">Go to Dashboard {">"} </a>;
  // };
  // const displayProdLink = (url) => {
  //   if (url)
  //     return (
  //       <a href={url} target="_blank">
  //         View Prod Detail {">"}{" "}
  //       </a>
  //     );
  //   else
  //     return <a className="card__footer__disabled">View Prod Detail {">"} </a>;
  // };
  return (
    <Fragment>
      <div className='appCard'>
        <div
          className={
            appLink ? "appCard__content" : "appCard__content appCard__disabled"
          }
        >
          {/* <div className='appCard__img'>
            <img
              src={CardDec1}
              alt='card decoration'
              className='appCard__img__dec1'
            />
            <img src={img1} alt='card img' className='appCard__img__logo' />
            <img
              src={CardDec2}
              alt='card decoration'
              className='appCard__img__dec2'
            />
          </div> */}
          <div className='appCard__heading'>
            {/* <img src={JSIVelocity} alt='JSI Velocity' /> */}

            <div> {cardDetails.name}</div>
          </div>
          <div className='appCard__title'>
            <div className='appCard__title__text'>
              {/* <ul>
                <li>{cardDetails.portalTab}</li>
              </ul>
              <ul>
                <li>{cardDetails.category}</li>
              </ul> */}
              <div> {cardDetails.description}</div>
            </div>
          </div>
        </div>
        {cardDetails?.appId === "3217810" ||
        cardDetails?.appId === "3217960" ||
        cardDetails?.appId === "3281598" ? (
          <Fragment>
            {extAppUrl ? (
              <div className='appCard__footer_m'>
                <a href={appUrl} target='_blank'>
                  <div className='appCard__footer_m__disabled'>
                    View Details
                  </div>
                </a>

                <a href={extAppUrl} target='_blank'>
                  <div className='appCard__footer_m__launch'>LAUNCH APP</div>
                </a>
              </div>
            ) : (
              <div className='appCard__footer'>
                {appLink && (
                  <a href={appUrl} target='_blank'>
                    <div className='appCard__footer__disabled'>
                      View Details
                    </div>
                  </a>
                )}
              </div>
            )}
          </Fragment>
        ) : (
          <div className='appCard__footer'>
            {appLink ? (
              <a href={appUrl} target='_blank'>
                <div className='appCard__footer__launch'>LAUNCH APP</div>
              </a>
            ) : (
              <a href={appUrl} target='_blank'>
                <div className='appCard__footer__disabled'>View Details</div>
              </a>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Card;
