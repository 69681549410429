import React, { useContext, useEffect, useState } from "react";
import Card from "./Card";
import AuthContext from "../Context/auth/AuthContext";
const AppLink = ({ cardDetails }) => {
  const authContext = useContext(AuthContext);
  const { user, isAuthenticated } = authContext;
  const [appLink, setAppLink] = useState("");
  const [extAppLink, setExtAppLink] = useState("");
  const [isAppLink, setIsAppLink] = useState(false);
  const [isExtAppLink, setIsExtAppLink] = useState(false);

  const isUserAuthorized = () => {
    if (
      user &&
      cardDetails &&
      cardDetails.role_ids &&
      cardDetails.role_ids.length > 0
    )
      return cardDetails.role_ids.filter((item) => user.role_ids.includes(item))
        ?.length;
    else if (user) return true;
    else return false;
  };

  useEffect(() => {
    // console.log("USER CARD**", user);
    // console.log("App Link", cardDetails.configuration.login_url);
    // console.log(
    //   "App Tab :%s, App: %s, Auth method: %d",
    //   cardDetails.portalTab,
    //   cardDetails.name,
    //   cardDetails.auth_method
    // );
    // console.log("Prod Link", isUserAuthorized());
    if (cardDetails?.appId === "3281598") {
      setAppLink(cardDetails.prodSpecUrl);
      setIsAppLink(true);
    } else if (
      cardDetails?.appId === "3217810" ||
      cardDetails?.appId === "3217960"
    ) {
      setAppLink(cardDetails.prodSpecUrl);
      setExtAppLink(cardDetails.extAppUrl);
      setIsAppLink(true);
      setIsExtAppLink(true);
    } else if (user && isAuthenticated && isUserAuthorized()) {
      if (cardDetails.auth_method === 2) {
        if (cardDetails.configuration.url) {
          setAppLink(cardDetails.configuration.url);
        } else {
          setAppLink(cardDetails.sso.acs_url);
        }
      } else {
        setAppLink(cardDetails.configuration.login_url);
      }
      setIsAppLink(true);
    } else {
      setIsAppLink(false);
      if (cardDetails.prodSpecUrl !== "") {
        setAppLink(cardDetails.prodSpecUrl);
      } else {
        setAppLink("");
      }
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      {/* {user && localStorage.getItem("isAuthenticated") ? (
        <a href={cardDetails.configuration.login_url} target="_blank">
          <Card cardDetails={cardDetails} user={user} />
        </a>
      ) : ( */}
      {!appLink ? (
        <Card cardDetails={cardDetails} user={user} />
      ) : (
        <Card
          cardDetails={cardDetails}
          user={user}
          appLink={isAppLink}
          appUrl={appLink}
          extAppLink={isExtAppLink}
          extAppUrl={extAppLink}
        />
      )}

      {/* )} */}
    </>
  );
};

export default AppLink;
